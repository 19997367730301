<template>
  <div
    class="m-spot-item"
    :class="{ '-secondary': isSecondary }"
  >
    <base-link
      class="text-decoration-none"
      :link="spot.link ? spot.link.url : null"
      :target="spot.link ? spot.link.target : null"
    >
      <base-image
        v-if="spot.image && spot.image[0]"
        :url="spot.image[0].Url"
        :width="isSecondary ? 60 : 278"
        :height="isSecondary ? 60 : 110"
        :ratio="isSecondary ? 60/60 : 278/110"
        :alt-text="spot.imageAltText"
        class="m-spot-item__figure"
        image-class="m-spot-item__image"
      />
      <div class="m-spot-item__text">
        <p
          v-if="$isNotNullOrUndefined(spot.headline)"
          class="m-spot-item__headline"
        >
          {{ spot.headline }}
        </p>
        <p
          v-if="$isNotNullOrUndefined(spot.subline)"
          class="m-spot-item__subline"
          v-html="spot.subline"
        >
          <!-- subline -->
        </p>
        <p
          v-if="$isNotNullOrUndefined(spot.text)"
          class="m-spot-item__description"
          v-html="spot.text"
        >
          <!-- text -->
        </p>
        <span v-if="spot.link && spot.link.name" class="m-spot-item__cta">
          {{ spot.link.name }}
          <base-icon icon="chevron-right" />
        </span>
      </div>
    </base-link>
  </div>
</template>

<script>
import BaseLink from '@/components/atoms/BaseLink';
import BaseImage from '@/components/atoms/BaseImage';
import BaseIcon from '@/components/atoms/BaseIcon.vue';

export default {
  name: 'SpotItem',
  components: {
    BaseLink,
    BaseImage,
    BaseIcon
  },
  props: {
    spotItem: {
      default () {
        return {};
      },
      requirred: true,
      type: Object
    }
  },
  data () {
    return {
      secondaryLayout: ['project']
    }
  },
  computed: {
    isSecondary () {
      return this.secondaryLayout.includes(this.spotItem.ncContentTypeAlias)
    },
    spot () {
      let spot = this.spotItem;
      spot.headline = this.spotItem.headlineSecondary ? this.spotItem.headlineSecondary : this.spotItem.headline;
      spot.subline = this.spotItem.sublineSecondary ? this.spotItem.sublineSecondary : this.spotItem.subline;
      spot.image = this.spotItem.imageSecondary ? this.spotItem.imageSecondary : this.spotItem.image;
      spot.text = this.spotItem.textSecondary ? this.spotItem.textSecondary : this.spotItem.text;
      return spot;
    }
  }
};
</script>
