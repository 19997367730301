<template>
  <div class="a-dropdown" v-bind="$attrs" :class="{ '-open': isOpen }">
    <div
      class="a-dropdown__title"
      tabindex="0"
      @click="onToggle"
      @focusout="onFocusOut"
    >
      {{ title }}
      <base-icon icon="chevron-down" />
    </div>
    <div class="a-dropdown__options">
      <div
        v-for="(item, i) in options"
        :key="i"
        :data-key="item.key"
        class="a-dropdown__option"
        :class="{ '-selected': isSelected(item) }"
        @mousedown="onSelect(item)"
      >
        <span class="a-dropdown__value">{{ item.value }}</span>
        <span v-show="isSelected(item)" class="a-dropdown__remove">
          <base-icon icon="close" />
        </span>
      </div>
      <div
        v-if="selected.length"
        class="a-dropdown__option -highlight"
        @click="onClear"
      >
        <span class="a-dropdown__value">{{ $t('DropdownClear', 'Ryd alle') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/atoms/BaseIcon';

export default {
  name: 'BaseDropdown',
  components: {
    BaseIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => {}
    },
    selected: {
      type: Array,
      default: () => {}
    }
  },
  data () {
    return {
      isOpen: false,
    }
  },
  methods: {
    onSelect (item) {
      this.$emit('onSelect', item)
    },
    onClear () {
      this.$emit('onClear')
    },
    onToggle () {
      this.isOpen = !this.isOpen
    },
    isSelected (item) {
      return this.selected.some(s => s.key === item.key);
    },
    onFocusOut () {
      // Add small delay, so that users sees the option being selected/deselected
      setTimeout(() => {
        this.isOpen = false;
      }, 150)
    }
  }
}
</script>