<template>
  <div class="m-spots-carousel" :class="{ '-align-right': isAlignedRight }">
    <div class="row">
      <div class="col-lg-3" :class="{ 'order-lg-2' : isAlignedRight }">
        <div class="m-spots-carousel__content">
          <base-image
            v-if="image && image[0]"
            :url="image[0].Url"
            :width="356"
            :height="545"
            image-class="m-spots-carousel__image"
            :alt-text="imageAltText"
          />
          <h2
            v-if="$isNotNullOrUndefined(headline)"
            class="m-spots-carousel__headline"
          >
            {{ headline }}
          </h2>
        </div>
      </div>
      <div class="px-0 col-lg-9" :class="{ 'order-lg-1' : isAlignedRight }">
        <div v-if="$isNotNullOrUndefined(spotsFiltered) && spots.length > 0" class="m-spots-carousel__glide">
          <vue-glide
            v-bind="glideOptions"
          >
            <vue-glide-slide
              v-for="(spot, i) in spotsFiltered"
              :key="i"
            >
              <spot-item-carousel :spot-item="spot" />
            </vue-glide-slide>
            <template
              v-if="spots.length > glideOptions.perView"
              slot="control"
            >
              <div data-glide-dir="<">
                <base-arrow
                  class="arrow -left -inverted"
                  aria-label="previous"
                />
              </div>
              <div data-glide-dir=">">
                <base-arrow
                  class="arrow -inverted"
                  aria-label="next"
                />
              </div>
            </template>
          </vue-glide>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Glide, GlideSlide } from 'vue-glide-js';
import 'vue-glide-js/dist/vue-glide.css';
import SpotItemCarousel from '@/components/molecules/SpotItemCarousel';
import BaseArrow from '@/components/atoms/BaseArrow';
import BaseImage from '@/components/atoms/BaseImage';

export default {
  name: 'SpotsCarousel',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    BaseArrow,
    SpotItemCarousel,
    BaseImage
  },
  props: {
    spots: {
      type: Array,
      default: () => {}
    },
    headline: {
      type: String,
      default: '',
    },
    image: {
      type: [Object, Array],
      default: () => {},
    },
    imageAltText: {
      type: String,
      default: '',
    },
    isAlignedRight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      glideOptions: {
        gap: 30,
        rewind: false,
        perView: 3.5,
        bound: true,
        bullet: true,
        direction: this.isAlignedRight ? 'rtl' : 'ltr',
        breakpoints: {
          768: {
            perView: 1.3,
          },
          1024: {
            perView: 2.3,
          }
        }
      }
    };
  },
  computed: {
    spotsFiltered () {
      return this.spots.filter(spot =>
        // If not a project
        spot.ncContentTypeAlias !== 'project' ||
        // If project and at least one of the text has content
        (spot.ncContentTypeAlias === 'project' &&
          (spot.headlineSecondary.length || spot.sublineSecondary.length || spot.textSecondary.length)
        )
      )
    }
  }
}
</script>