<template>
  <div v-if="breadcrumb.length > 0" class="m-breadcrumb" v-bind="$attrs">
    <nav class="m-breadcrumb__nav">
      <base-link
        v-for="(breadcrumbItem, index) in breadcrumb"
        :key="index"
        class="m-breadcrumb__link"
        :link="breadcrumbItem.link.url"
      >
        <span class="m-breadcrumb__item-text">{{ breadcrumbItem.link.name }}</span>
      </base-link>
    </nav>
  </div>
</template>

<script>

import BaseLink from '@/components/atoms/BaseLink.vue';

export default {
  name: 'Breadcrumb',
  components: {
    BaseLink
  },
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  computed: {
    navigationItems () {
      return this.$store.getters['navigation/getNavigationItems'];
    },
    id () {
      return this.isProductPage ? this.product.primaryCategoryId : this.$store.getters['content/content/getContent']?.meta?.id;
    },
    type () {
      return this.$store.getters['content/content/getContent']?.meta?.type;
    },
    isCategoryPage () {
      return this.type === 'ContentProductList' || this.type === 'ContentCategory';
    },
    isProductPage () {
      return this.type === 'ContentProduct' && !!this.product;
    },
    breadcrumb () {
      let breadcrumb = [];
      let path = this.find(this.navigationItems, this.id);
      if (path !== null) {
        // inject frontpage which is not in navigation
        let frontPage = [{
          link: {
            url: '/',
            name: this.$t('Home', 'Forside')
          }
        }];
        breadcrumb = frontPage.concat(path)
      }
      return breadcrumb;
    }
  },
  methods: {
    find (array, id) {
      if (typeof array != 'undefined' && this.$isNotNullOrUndefined(array)) {
        for (var i = 0; i < array.length; i++) {
          if (array[i].id == id) return [array[i]];
          var a = this.find(array[i].children, id);
          if (a != null) {
            a.unshift(array[i]);
            return a;
          }
        }
      }
      return null;
    }
  }
};
</script>
